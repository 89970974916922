import React, {useState, useEffect, useRef} from "react";
import getBrowserFingerprint from "get-browser-fingerprint";
import { BrowserRouter } from "react-router-dom";
import { SettingsContextProvider } from "./store/settings";
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from "./store/auth";
import SocketClient from "./socket-client";
import {DefaultTheme} from "./assets/theme";
import ApplicationRoutes from "./routes";

const uuid = getBrowserFingerprint();



export default function App() {
	
	const [tenantId, setTenantId] = useState(null);
	const [botId, setBotId] = useState(null);
	const [host, setHost] = useState(null);
	const [lang, setLang] = useState(null);
	const socketRef = useRef(null);
	
	const propsInterval = setInterval(widgetTimer, 1000);

	function widgetTimer() {
		const tenantIDFromStorage = localStorage.getItem("wws-tenant-id");
		const botIDFromStorage = localStorage.getItem("wws-bot-id");
		const hostURLFromStorage = localStorage.getItem("wws-host");
		const contactLangFromStorage = localStorage.getItem("wws-contact-language");

		if((tenantIDFromStorage && tenantIDFromStorage != "undefined") && (botIDFromStorage && botIDFromStorage != "undefined") && (hostURLFromStorage && hostURLFromStorage != "undefined") && (contactLangFromStorage && contactLangFromStorage != "undefined")){
			clearInterval(propsInterval);
			setTenantId(tenantIDFromStorage);
			setBotId(botIDFromStorage);
			setHost(hostURLFromStorage);
			setLang(contactLangFromStorage)
		}
	}
	useEffect(()=>{
		if(uuid && tenantId && botId && !socketRef.current){
			socketRef.current = SocketClient.getInstance(tenantId, uuid, botId);
			global.socket = socketRef.current;
		}
	}, [uuid, tenantId, botId]);

	return (
		<>
			{tenantId && botId && host && lang && (
				<AuthProvider>
					<ThemeProvider theme={DefaultTheme}>
						<SettingsContextProvider>
							<BrowserRouter>
								<ApplicationRoutes 
									tenantId={tenantId} 
									botId={botId} 
									host={host} 
									lang={lang}
									uuid={uuid}
								/>
							</BrowserRouter>
						</SettingsContextProvider>
					</ThemeProvider>
				</AuthProvider>
			)}
		</>
	);
}