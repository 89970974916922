import React, {useEffect, useState} from "react";
import { get } from "lodash";
import { BiSend } from "react-icons/bi";
import { 
	IconButton,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	Typography,
} from "@mui/material";
import Message from "./TextMessage";

const DataCollector = (props) => {
	const {contact, tenantId, content, query, handleRequest, setHiddenRecords, recordId} = props;
	const [innerAttrs, setInnerAttrs] = useState([]);
	const [msg, setMsg] = useState(null);
	const [currAttr, setCurrentAttr] = useState(null);

	const msgProps = {
		content: {
			message: "Thank you for providing your data, it's secure with us."
		}
	};

	const getNextAttr = (attrs) => {
		for(let i = 0; i < attrs.length; i++){
			if(!attrs[i].value) return attrs[i];
		}
		return null;
	};
	const handleSubmit = async () => {
		const _attrs = innerAttrs.map(attr => {
			if(attr.name === currAttr.name){
				attr.value = currAttr.value;
			}
			return attr;
		});
		setInnerAttrs(_attrs);
	};

	useEffect(()=>{
		if(contact && content){
			setInnerAttrs(content.config.attrs.map(attr => {
				attr.value = "";
				
				if(contact.meta[attr.name]){
					attr.value = contact.meta[attr.name];
				}
				return attr;
			}));
			const _msg = get(content, "config.msg", null);
			if(_msg){
				setMsg(_msg);
			}
		}
	}, [contact, content]);

	useEffect(()=>{
		if(Array.isArray(innerAttrs) && innerAttrs.length > 0){
			const nextAttr = getNextAttr(innerAttrs);
			if(nextAttr){
				setCurrentAttr(nextAttr);
			}else{
				setCurrentAttr(null);
				handleRequest({
					action_query: {
						data: content.actionId,
						flow: content.flow,
						callback: {
                            data: {
                                prevQuery: query,
								attrs: innerAttrs,
                            }
						}
					}
				});
				setHiddenRecords(prevRecords => {
                    return [...prevRecords, recordId]
                })
			}
		}
	}, [innerAttrs]);
	return (
		<>
			<Typography 
				variant="body1"
				sx={{marginBottom: "10px"}}
			>
				{msg ? msg : ""}
			</Typography>

			{currAttr && (
				<FormControl
					variant="outlined"
					fullWidth
					size="small"
					sx={{width: "90%", margin: "0 auto"}}
				>
					<InputLabel
						htmlFor="prompt">
						{currAttr.name}
					</InputLabel>
					<OutlinedInput
						id="prompt"
						onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
						onChange={(e) => { setCurrentAttr({...currAttr, value: e.target.value}); }}
						value={currAttr.value}
						autoFocus={true}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									edge="end"
									color="primary"
									disabled={currAttr.value.trim() === ""}
									onClick={handleSubmit}
								>
									<BiSend />
								</IconButton>
							</InputAdornment>
						}
						label="Ask me anything"
						sx={{ backgroundColor: "#FFFFFF" }}
					/>
				</FormControl>
			)}
			{!currAttr && (
				<Message {...msgProps}/>
			)}
		</>
	);
};

export default DataCollector;