import React, {useState, useEffect} from "react";
import { BiSend } from "react-icons/bi";
import {
	Box,
	Stack,
	FormControl,
	InputAdornment,
	OutlinedInput,
	IconButton
} from '@mui/material';

import History from "./history";
const Chat = (props) => {
	const {faq, setFaq, handleRequest} = props;
	const [query, setQuery] = useState("");
	const [lastQuery, setLastQuery] = useState("");
    
	const handleSubmit = (msg) => {
		handleRequest({
			msg_query: {
				text: query || msg
			}
		});
		setLastQuery(query);
		setQuery("");
	};

	useEffect(()=>{
		if(faq && faq?.question){
			setQuery(faq.question);
			handleSubmit(faq.question)
			setFaq(null)
		}
	}, [faq])
	return (
		<Box sx={{height: "100%", margin: "0px", overflowY: "hidden"}}>
			<Stack sx={{height: "100%", justifyContent: "space-between"}}>
				<History
					{...props}
					query={lastQuery}
				/>
				<FormControl
					variant="outlined"
					fullWidth
					size="small"
					className="chat-input"
				>
					<OutlinedInput
						onKeyPress={(event) => event.key === "Enter" && handleSubmit()}
						onChange={(e) => { setQuery(e.target.value); }}
						value={query}
						autoFocus={true}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="open chat screen"
									edge="end"
									color="primary"
									disabled={query.trim() === ""}
									onClick={handleSubmit}
								>
									<BiSend />
								</IconButton>
							</InputAdornment>
						}
						placeholder="Ask me anything"
						sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", height: "48px" }}
					/>
				</FormControl>
			</Stack>
		</Box>
	);
};

export default Chat;