import React, {useState, useEffect} from "react";
import axios from "axios";
import getBrowserFingerprint from "get-browser-fingerprint";
import { IoChevronBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

import { get } from "lodash";
import {
	Box,
	Stack,
	Card,
	CardContent,
	CardHeader,
	Avatar,
	Typography,
	IconButton,
} from "@mui/material";
import eventEmitter from "./../../components/EventEmitter"
import useSettingsSelector from './../../store/selectors/settings';
import Logo from "./../../assets/images/is_logo.png";
import AvatarStack from "./../../components/AvatarStack";
import Home from "./../Home";
import Chat from "./../Chat";
import {
	INTELSWIFT_GRAY_LOGO,
} from './../../constants';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACK_END_BASE_URL || "http://localhost/atombot/api/v1" });
let count = 0
const Landing = (props) => {
	const {botId, tenantId, widget, lang, uuid} = props;
	const {
		HEADER,
		SUB_FOOTER
	} = useSettingsSelector();

	const [page, setPage] = useState("home");
	const [faq, setFaq] = useState([]);
	const [faqs, setFaqs] = useState([]);
	// const [loading, setLoading] = useState(true);
	const [contact, setContact] = useState(null);
	// const [conversation, setConversation] = useState(null);
	const [agents, setAgents] = useState([]);
	const [records, setRecords] = useState([]);
	const [hiddenRecords, setHiddenRecords] = useState([])

	// const HOME_ICON_COLOR = HOME_ICON(page === "home" ? FOOTER.FONT_COLOR : FOOTER.FONT_UN_SELECTED_COLOR);
	// const CHAT_ICON_COLOR = CHAT_ICON(page === "chat" ? FOOTER.FONT_COLOR : FOOTER.FONT_UN_SELECTED_COLOR); 
	const HEADER_BACKGROUND =  get(widget, "chat.header.backgroundColor", HEADER.BACKGROUND_COLOR);
	const TITLE =  get(widget, "chat.header.title", "");
	// const BODY_BACKGROUND =  CONTENT.BACKGROUND_COLOR;
    

	const hideWidget = () => {
		window.parent.postMessage({ hideWidget: true },"*");
	};
	const handleFaq = (faq) => {
		setPage("chat");
		setFaq(faq);
	};
	
	const handleRequest = async (content) => {
		if(content.msg_query){
			setRecords([...records, {query: content.msg_query.text, reply: null}]);
		}
        //TODO: this call should be verified in UCS
		return await axiosInstance.post(`/widgets/query`, {
			content: content,
			tenantId: tenantId,
			botId: botId,
			contactId: uuid,
			lang: lang,
			href: window.location.hostname 
		});
	};
	const initSessionAndGetContact = async (contactId, tenantId, botId) => {
		const response = await handleRequest({
			new_session: true
		});
		const _contact = get(response, "data.data", null);
		if(_contact){
			setContact(_contact);
		}
	};
	const fetchConversation = async (uuid, tenantId, botId) => {
		const response = await axiosInstance.get(`/conversations/details/${uuid}_${botId}?botId=${botId}&tenantId=${tenantId}`);
		const _conversation = get(response, "data.data", null);
		if(_conversation){
			// setConversation(_conversation);
			setRecords(_conversation.records);
		}
		// setLoading(false);
	};
	
	useEffect(() => {
		if (uuid && widget && tenantId && botId) {
			initSessionAndGetContact(uuid, tenantId, botId);
			fetchConversation(uuid, tenantId, botId);
		}
	}, [uuid, widget, tenantId, botId]);

	useEffect(()=>{
		if(widget){
			setFaqs(widget.faqs);
		}
	}, [widget]);

	const handleAgents = (message) => {
		try{
			const { agents } = message;
			if(Array.isArray(agents)){
				setAgents(agents.filter(el => el.name && el.profileURL && el.presence === 1))
			}
		}catch(error){
			console.error("@@ Error: handleAgents @@", error);
		}
		
	}
	const handleReply = (message) => {
		try{
			const { contactId, content } = message;
			if (contactId == `${uuid}_${botId}`) {
				setRecords(prevRecords => {
					return [...prevRecords, {
						query: null, 
						reply: content, 
						id: count++
					}];
				})
			}
			
		}catch(error){
			console.error("@@ Error: handleReply @@", error);
		}
		
	}
	useEffect(() => {
        eventEmitter.on('agents_update', handleAgents);
		eventEmitter.on('reply_update', handleReply);
        // Cleanup listener on unmount
        return () => {
          eventEmitter.off('agents_update', handleAgents);
		  eventEmitter.off('reply_update', handleReply);
        };
    }, []);
	return (
		<>
			<Box sx={{ width: '100%', }} id="wws">
				<Card className="widget-root">
					<CardHeader
						sx={{
							background: page === "home" ? `linear-gradient(45deg, #000000 0%, ${HEADER_BACKGROUND} 100%)` : 'white', 
							padding: "20px", 
							height: page === "home" ? "200px" : "35px",
							borderBottom: page === "home" ? "none" : "1px solid lightgrey",
						}}
						avatar={ page === "home" ? null : (
							<IconButton aria-label="back">
								<IoChevronBack onClick={()=>{
									setPage('home');
								}}/>
							</IconButton>
						)}
						action={
							<IconButton aria-label="close">
							  <IoMdClose onClick={hideWidget}/>
							</IconButton>
						}
						titleTypographyProps={
							{
								color: "white",
								fontWeight: "600",
								fontSize: "27px",
								lineHeight: "32.4px"
							}
						}
						title={page === "home" ? TITLE : (
							<Box sx={{}}>
								<Stack sx={{
									alignItems: 'center',
									padding: "10px 0"
								}}>
									{/* <AvatarStack 
										agents={agents || []}
									/>
									<Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
										AI agent answers instantly
									</Typography>
									<Typography variant="subtitle2">
										Ask for the team if needed
									</Typography> */}
								</Stack>
							</Box>
						)}
					/>
					<CardContent
						className = {page === "home" ? "home-content" : "chat-content"}
						sx={{
							overflowY: 'auto',
							position: 'relative',
							padding: '0 !important',
							overflowY: 'scroll',
							marginTop: page === "home" ? "-80px" : "0"
						}}>
						{page === "home" && (
							<>
								<Home
									{...props}
									faqs={faqs}
									agents={agents}
									setPage={setPage}
									handleFaq={handleFaq}
								/>
							</>
						)}
						{page === "chat" && (
							<>
								<Chat
									{...props}
									widget={widget}
									agents={agents}
									tenantId={tenantId}
									records={records}
									hiddenRecords={hiddenRecords}
									setHiddenRecords={setHiddenRecords}
									contact={contact}
									faq={faq}
									setFaq={setFaq}
									handleRequest={handleRequest}
								/>
							</>
						)}
					</CardContent>
					<Stack direction="column">
						<Box sx={{
							bgcolor: SUB_FOOTER.BACKGROUND_COLOR,
							padding: '16px !important',
						}}>
							<div style={{
								display: 'flex',
								gap: '10px',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
								{INTELSWIFT_GRAY_LOGO}
								<span style={{color: 'black'}}>Powered by <a href="https://intelswift.com/" style={{color: 'inherit'}} target='_blank' rel="noreferrer">IntelSwift</a></span>
							</div>
						</Box>
					</Stack>
				</Card>
			</Box>
			{/* <WidgetButton /> */}
		</>
	);
};

export default Landing;